import axios from 'axios'
const config = { apiUrl: process.env.REACT_APP_API_URL }
const authHeader = require('./authHeader')

function getAllAdvertisements (groupId) {
  if (groupId && typeof groupId === 'string') {
    const options = {
      headers: authHeader.authHeader()
    }

    return axios.get(`${config.apiUrl}group/${groupId}/advertisements`, options)
      .then((response) => {
        return response.data
      })
  } else {
    return Promise.resolve([])
  }
}

function getAdvertisements ({ groupId, data, options }) {
  if (groupId && typeof groupId === 'string') {
    options.headers = authHeader.authHeader()

    return axios.post(`${config.apiUrl}group/${groupId}/advertisements/home`, data, options)
      .then((response) => {
        return response.data
      })
  } else {
    return Promise.resolve([])
  }
}

function getHomeAdvertisements (groupId, signal) {
  if (groupId && typeof groupId === 'string') {
    const options = {
      headers: authHeader.authHeader(),
      signal
    }

    return axios.get(`${config.apiUrl}group/${groupId}/advertisements/home`, options)
      .then((response) => {
        return response.data
      })
  } else {
    return Promise.resolve([])
  }
}

function getBottlesAdvertisements (groupId, signal) {
  if (groupId && typeof groupId === 'string') {
    const options = {
      headers: authHeader.authHeader(),
      signal
    }

    return axios.get(`${config.apiUrl}group/${groupId}/advertisements/bottles`, options)
      .then((response) => {
        return response.data
      })
  } else {
    return Promise.resolve([])
  }
}

function createNewAdvertisement ({ groupId, ...data }) {
  if (groupId && typeof groupId === 'string') {
    const options = {
      headers: authHeader.authHeader()
    }

    return axios.post(`${config.apiUrl}group/${groupId}/advertisements`, data, options)
      .then((response) => {
        return response.data
      })
  } else {
    return Promise.resolve([])
  }
}

function editAdvertisement ({ groupId, ...data }) {
  if (groupId && typeof groupId === 'string') {
    const options = {
      headers: authHeader.authHeader()
    }

    return axios.put(`${config.apiUrl}group/${groupId}/advertisements`, data, options)
      .then((response) => {
        return response.data
      })
  } else {
    return Promise.resolve([])
  }
}

function publishAdvertisement (groupId, adId, publish) {
  if (groupId && typeof groupId === 'string') {
    const options = {
      headers: authHeader.authHeader()
    }

    return axios.post(`${config.apiUrl}group/${groupId}/advertisements/${adId}/publish`, { publish }, options)
      .then((response) => {
        return response.data
      })
  } else {
    return Promise.resolve([])
  }
}

export const adService = {
  getAllAdvertisements,
  getHomeAdvertisements,
  getBottlesAdvertisements,
  createNewAdvertisement,
  editAdvertisement,
  publishAdvertisement,
  getAdvertisements
}
